.profile-img {
    margin-top: -4.375rem !important;
    img {
        border: 4px solid $white;
    }
}
.comment-attagement {
    position: absolute;
    left: auto;
    right: 1.875rem;
    font-size: 1.25rem;
}

@media (max-width: 767px) {
    .profile-img {
        margin-top: 0 !important;
    }
}