.show-comment-btn {
	position: fixed;
	top: 50%;
	right: 0;
	background-color: #cdff7b;
	color: black;
	padding: 10px 20px;
	border-radius: 30px 0 0 30px;
	cursor: pointer;
	transform: translateY(-50%);
	z-index: 50;
	display: "block";
}
.hide-comment-btn {
	display: "none";
	transition: right 2s ease-in-out;
}

.collapsible {
	right: 1000px;
	width: 0;
	height: 100%; /* Adjust the height as needed */
	overflow: hidden;
	transition: width 0.5s linear;
	background: #333;
	color: #fff;
	padding: 1rem;
	border-radius: 0.25rem 0 0 0.25rem;
}
.collapsible.deactive {
	width: 0; /* Adjust the width as needed */
	// z-index: 999;
	right: -100px;
}
