.dayrade-header-container {
	height: 3.9rem;
	z-index: 10;
	margin-left: 8px;
	border-bottom-left-radius: 8px;
	overflow: hidden;
}

.dayrade-header {
	height: 100%;
	background-color: #222738;
	backdrop-filter: blur(10px);
}

// header title
.header-title-text {
	color: #d7d7d7;
	font-weight: 400;
}

// participate profile image animation
.surprise-bubble {
	position: relative;
	border-radius: 50%;
	width: 45px;
	height: 45px;
	// border: 1.5px solid white;
	padding: 1px;
	color: white;
	overflow: hidden;
	margin-right: -5px; /* Overlapping effect */
	-webkit-transition: all 0.55s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: all 0.55s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.surprise-bubble img {
	width: 45px;
	height: 45px;
	border-radius: 50%;
	margin-left: 1px;
}
.surprise-bubble span {
	font-size: 10px;
	letter-spacing: 1px;
	color: white;
	position: absolute;
	padding: 1px 2px;
	opacity: 0;
	left: 50px;
}
.surprise-bubble.active-hover,
.surprise-bubble:hover {
    margin-right: 10px;
    margin-left: 15px;
    border-radius: 30px;
    width: 145px;
    height: 45px;
    background: rgba(34, 39, 56, 1);
    border: 1.5px solid rgba(205, 255, 123, 1);
    transition: all 0.55s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.surprise-bubble.active-hover img,
.surprise-bubble:hover img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    position: absolute;
    top: 0px;
    left: 0px;
}

.surprise-bubble.active-hover span,
.surprise-bubble:hover span {
    opacity: 1;
    word-break: break-all;
    padding: 1px 6px;
}
.overflow-hidden {
	padding-right: 5px; /* Ensure enough space for the last image */
}

// marques section
section {
	overflow-x: hidden;
}

section div {
	display: flex;
	flex-wrap: nowrap;
	white-space: nowrap;
	min-width: 100%;
}

.news-message {
	display: flex;
	flex-shrink: 0;
	height: 45px;
	align-items: center;
	animation: slide-left 5s linear infinite;
}

.news-message p {
	font-weight: 100;
	padding-left: 0.5em;
}

@keyframes slide-left {
	from {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}

	to {
		-webkit-transform: translateX(-100%);
		transform: translateX(-100%);
	}
}

// vertical shadow
.left-shadow {
	box-shadow: inset 10px 0 10px -10px rgba(0, 0, 0, 0.46); /* Black shadows on both sides */
}
.left-right-shadow {
	box-shadow: inset -10px 0 10px -10px rgba(0, 0, 0, 0.46),
		inset 10px 0 10px -10px rgba(0, 0, 0, 0.46); /* Black shadows on both sides */
}
