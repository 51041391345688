[data-bs-theme='dark'] {
  --#{$prefix}gray-100: #181818;
  --#{$prefix}gray-100-rgb: 24, 24, 24;
  --#{$prefix}gray-200: #181818;
  --#{$prefix}gray-300: #222738;
  --#{$prefix}gray-400: #fff;
  --#{$prefix}gray-500: #2f2f2f;
  --#{$prefix}gray-600: #d7dbdf;
  --#{$prefix}gray-800: #8a92a6;
  --#{$prefix}gray-900: #222738;
  --#{$prefix}gray-900-rgb: 8, 8, 8;
}
