.alert-left {
    border: 0;
    border-left: $alert-borer-width $alert-borer-type;
    @each $state, $value in $theme-colors {
        &.alert-#{$state} {
            border-color: $value;
        }
    }
}
.alert-top {
    border: 0;
    border-top: $alert-borer-width $alert-borer-type;
    @each $state, $value in $theme-colors {
        &.alert-#{$state} {
            border-color: $value;
        }
    }
}
.alert-right {
    border: 0;
    border-right: $alert-borer-width $alert-borer-type;
    @each $state, $value in $theme-colors {
        &.alert-#{$state} {
            border-color: $value;
        }
    }
}
.alert-bottom {
    border: 0;
    border-bottom: $alert-borer-width $alert-borer-type;
    @each $state, $value in $theme-colors {
        &.alert-#{$state} {
            border-color: $value;
        }
    }
}
