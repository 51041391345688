@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .form-inner-row {
    @apply md:tw-flex md:tw-items-center;
  }

  .form-label-container {
    @apply md:tw-mr-1 md:tw-w-40;
  }

  .form-label {
    @apply tw-text-sm tw-text-on-dark sm:tw-text-lg;
  }

  .form-control-container {
    @apply md:tw-flex-1;
  }

  .form-control {
    @apply tw-w-full tw-rounded-md tw-border tw-border-on-dark tw-text-xs tw-leading-3;
    @apply tw-outline-none focus:tw-border-primary-dark sm:tw-text-sm;
  }

  .form-control-spacing {
    @apply tw-px-3 tw-py-3;
  }

  .form-control-error {
    @apply tw-border-error-dark focus:tw-border-error-dark;
  }

  .input-error-feedback {
    @apply tw-mt-1 tw-block tw-w-full tw-overflow-x-hidden;
    @apply tw-text-error-dark sm:tw-h-7 sm:tw-text-xs;
    height: 22px;
    font-size: 10px;
    line-height: 11px;
  }

  .custom-input {
    @apply tw-invisible tw-w-0 tw-appearance-none;
  }

  .custom-input-label {
    @apply tw-cursor-pointer;
  }

  .checkbox-radio {
    @apply tw-cursor-pointer;
  }

  .checkbox-radio-label {
    @apply tw-ml-2 tw-inline-block tw-cursor-pointer;
    transform: translateY(-1px);
  }

  .form-btn {
    @apply tw-cursor-pointer;
  }

  .form-btn-submitting {
    @apply tw-cursor-not-allowed;
  }
}

@media (min-width: 640px) {
  .form-control {
    line-height: 14px;
  }

  .input-error-feedback {
    line-height: 14px;
  }
}
