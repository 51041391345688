/*!
* Version: 4.1.0
* Template: Example Project
* Author: iqonic.design
* Design and Developed by: iqonic.design
* NOTE: This file contains the styling for Template.
*
*/

// Configuration
@import "./bootstrap/functions";

// Hopeui Design system
@import "./hope-ui-design-system/variables";
@import "./hope-ui-design-system/variables-dark";
@import "./hope-ui-design-system/variables/index";
@import "./bootstrap/variables";
@import "./bootstrap/variables-dark";
@import "./bootstrap/maps";
@import "./bootstrap/mixins";
@import "./bootstrap/utilities";

// Hope Ui Design System Mixin And Helper
@import "./hope-ui-design-system/helper/functions";
@import "./hope-ui-design-system/helper/mixins";
@import "./hope-ui-design-system/helper/reboot";

@import "./custom/auth/authentication";
@import "./custom/kanban/kanban";
@import "./custom/pricing/pricing";
@import "./custom/ui-kit/ui-kit";

// Dark Custom
@import "./custom/dark/dark-custom";
