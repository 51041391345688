/* .square-to-circle {
    background-color: darkgray;
    animation: square-to-circle 2s infinite alternate;
}

@keyframes square-to-circle {
    0% {
        border-radius: 0 0 0 0;
        background: teal;
    }

    100% {
        border-radius: 50%;
        background: teal;
        scale: 50%
    }
} */


.cs-container {
  position: relative;
  /* width: 250px;
  height: 250px; */
}

.qr-div {
  width: 100%;
  height: 100%;
}

.qr-div img {
  width: 100%;
  height: 100%;
  /* transition: transform 0.5s ease-in-out; */
}

.profile-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  animation: none;
  /* background-color: red; */
  overflow: hidden;
  border-radius: 15px;
}

.profile-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.zoom-in {
  animation: zoomIn 2s forwards;
}

.zoom-out {
  animation: zoomOut 1s forwards;
}

@keyframes zoomIn {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.24);
    /* Enclose zoom */
  }
}

@keyframes zoomOut {
  0% {
    /* transform: scale(0); */
    transform: scale(1);
    /* Start from full zoom */
  }

  100% {
    transform: scale(1);
  }
}