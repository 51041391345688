.iq-timeline0 {
    ul {
        li {
            width: 50%;
            padding: 5px 30px 2.5rem 30px;
            cursor: pointer;
            margin: 0px;
            &:nth-child(odd) {
                float: left;
                text-align: left;
                width: 100%;
                padding: 0 0 0 60px;
                margin-bottom: 20px;
                .timeline-dots {
                    left: 12px;
                    right: auto;
                }
                .timeline-dots.timeline-dot1 {
                    left: 12px;
                    right: auto;
                }
            }
            &:nth-child(even) {
                float: left;
                text-align: left;
                width: 100%;
                padding: 0 0 0 60px;
                margin-bottom: 20px;
                .timeline-dots {
                    left: 12px;
                    right: auto;
                }
                .timeline-dots.timeline-dot1 {
                    left: 12px;
                    right: auto;
                }
            }
        }
    }
    li {
        .timeline-dots {
            height: 15px;
            width: 15px;
            background: $white;
        }
        .timeline-dots1 {
            position: absolute;
            top: 0;
            left: 0;
            border: 3px solid #05bbc9;
            border-radius: 90px;
            padding: 5px;
            background: var(--bs-gray-900);
            height: 2.5rem;
            width: 2.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .timeline-dots.timeline-dot1 {
            height: 15px;
            width: 15px;
        }
    }
}
.iq-timeline0 {
	li {
		margin-left: 2.188rem;
		position: relative;
		padding: 15px 15px 0 5px;
		list-style-type: none;
		.timeline-dots {
			position: absolute;
			top: 20px;
			left: -54px;
			border: 3px solid #05bbc9;
			border-radius: 90px;
			padding: 5px;
			background: var(--bs-gray-900);
			height: 2.5rem;
			width: 2.5rem;
			line-height: 25px;
			text-align: center;
		}
	}
	&:before {
		content: '';
		position: absolute;
		left: 20px;
		transform: translateX(-50%);
		width: 2px;
		height: 100%;
		background-color: var(--bs-border-color);
	}
}