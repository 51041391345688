.d-slider1 {
    position: relative;

    .swiper-button {
        width: 30px;
        height: 30px;
        position: absolute;

        &.swiper-button-next {
            right: 5px;
            left: auto;
            top: 60px;

            &:after {
                content: 'next';
                font-size: 0.75rem;
                color: var(--bs-body-color);
            }
        }

        &.swiper-button-prev {
            &:after {
                content: 'prev';
                font-size: 0.75rem;
                color: var(--bs-body-color);
            }

            right:auto;
            left:12px;
            top:65px;
        }
    }
}