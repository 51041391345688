[data-bs-theme="dark"] {
    .mode {
        &.light-img {
            display: none;
        }
        &.dark-img {
            display: block;
        }
    }
}
[dir=rtl] {
    .mode {
        &.rtl-img {
            display: block;
        }
    }
}
.mode {
    display: none;
    &.light-img {
        display: block;
    }
    &.ltr-img {
        display: block;
    }
}