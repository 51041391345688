@layer components {
  .default-modal-container {
    @apply tw-fixed tw-left-0 tw-top-0 tw-z-50 tw-h-screen tw-w-screen;
    @apply tw-flex tw-items-center tw-justify-center tw-bg-overlay-background-dark;
  }

  .default-modal {
    @apply tw-bg-surface-dark tw-text-on-dark tw-w-80 tw-rounded-lg tw-p-5;
    max-width: 90%;
  }
}
