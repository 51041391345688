.fancy-table {
  table {
      thead {
          tr {
              th {
                  background-color: var(--bs-body-bg);
              }
          }
      }

      tbody {
          tr {
              &:nth-child(odd) {
                  td {
                      &:first-child {
                          border-top-left-radius: $border-radius;
                          border-bottom-left-radius: $border-radius;
                      }

                      &:last-child {
                          border-top-right-radius: $border-radius;
                          border-bottom-right-radius: $border-radius;
                      }
                  }
              }

              td {
                  background-color: var(--bs-gray-900);
                  border-bottom-width: 0;
              }
          }
      }
  }
}
