
.btn-landing {
    top: 50%;
    left: -3.6rem ;
    right: auto;
    border-bottom-left-radius: 0;
    transform: rotate(90deg);
    border-bottom-right-radius: 0rem;
    border-top-left-radius: 0.25rem;
    padding: .25rem .5rem;
    position: fixed;
}
.btn-fixed-end.btn-dashboard,
.btn-dashboard {
    top: 68%;
    left: -3rem ;
    right: auto;
    border-bottom-left-radius: 0;
    transform: rotate(90deg);
    border-bottom-right-radius: 0rem;
    border-top-left-radius: 0.25rem;
    padding: .25rem .5rem;
    position: fixed;
}