.offcanvas.live-customizer {
    box-shadow: $dropdown-box-shadow;
    border: 0;
}

@media (min-width: 768px) {
    .offcanvas.live-customizer {
        width: 450px;
    }
    .theme-fs-lg{
        .offcanvas.live-customizer {
            width: 500px;
        }
    }
}